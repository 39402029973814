.secondary-header-item-cta-link {
	width: 200px;
	font-size: 0.7rem;
	line-height: 0.8rem;
}

.squadron-container.zipcode {
	width: 400px;
}

.form-field.header-search-field {
	width: 200px;
}

input[type=text],
input[type=search],
input[type=password],
input[type=email],
textarea,
select {
	border: 1px solid #333;
}

@media (min-width: 992px) {
	.i-am-a-text.find {
		width: 150px;
		line-height: 1.1rem;
	}
}


.donate-link {
	width: 450px;
}

@media (max-width: 767px) {
	.secondary-header-item-cta-link {
		width: 200px;
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		padding-top: 0.75em;
		padding-right: 2.5em;
		padding-left: 1.5em;
		background-position: 90% 50%;
		background-size: 8px 8px;
	}
}

@media (max-width: 479px) {
	.donate-link {
		width: 320px;
		text-align: center;
	}
}

.module-search input[type="search"] {
	padding: 12px 10px;
}